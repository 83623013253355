/* global BigInt */
import logo from './resources/logo.jpg';
import logomaga from './resources/logomaga.jpg';
import logopepe from './resources/logopepe.jpg';
import fighters from './resources/fighters.json';
import overwrite_rounds from './resources/overwrite_rounds.json';
import vince_water from "./resources/vince_water.jpg";
import './App.css';
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createWeb3Modal, useWeb3ModalProvider, defaultConfig, useWeb3ModalError } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, toBigInt } from 'ethers'
import CountdownComponent from "./Countdown.js"

const REACT_APP_ALCHEMY_RPC_URL = process.env.REACT_APP_ALCHEMY_RPC_URL;

//WalletConnect

// 1. Get projectId
const projectId = '141cbd354a18faa313fe85e851daaa20'

// 2. Set chains
//const mainnet = {
//  chainId: 1,
//  name: 'Ethereum',
//  currency: 'ETH',
//  explorerUrl: 'https://etherscan.io',
//  rpcUrl: 'https://cloudflare-eth.com'
//}

const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: REACT_APP_ALCHEMY_RPC_URL
}

// 3. Create a metadata object
const metadata = {
  name: 'Vince Fight',
  description: 'Support your favorite fighter with $VINCE',
  url: 'https://vincefight.vercel.app/', // origin must match your domain & subdomain
  icons: ['https://pbs.twimg.com/profile_images/1763843305723535360/OTDkTs4d_400x400.jpg']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,
  rpcUrl: REACT_APP_ALCHEMY_RPC_URL,
  defaultChainId: 1,

  /*Optional
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK*/
})

// 5. Create a Web3Modal instance
const web3Modal = createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  themeMode: "dark",
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
  ]
})

//Definition of constants

const vinceFightAddress = "0xB8bB428f86e345f0652FB583FB23D8ECC4Abb85E";
const vinceAddress = "0xDccf3968b667e515C9FC952aA6Bf834eb9d8476c";
const magaAddress = "0xD29DA236dd4AAc627346e1bBa06A619E8c22d7C5";
const pepeAddress = "0x6982508145454Ce325dDbE47a25d4ec3d2311933";
const wethAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";

function MainApp() {

//Print errors
function errorManager(e){
if (e.reason=="rejected") {}
else if (e.reason==null && {showdown}) {window.alert("You must connect to Vince Fight first")}
else if (e.reason=="SafeMath: subtraction overflow") {window.alert("Probably you are trying to spend more than what you have.")}
else if (e.reason=="Too little received") {window.alert("Transaction failed due to low liquidity. Try to increase the slippage.")}
else (window.alert(e.reason))
}

const { walletProvider } = useWeb3ModalProvider()

const [amount, setAmount] = useState('')
const [toggleFighter0, setToggleFighter0] = useState(false)
const [toggleFighter1, setToggleFighter1] = useState(false)
const [toggleVince, setToggleVince] = useState(false)
const [toggleMaga, setToggleMaga] = useState(false)
const [togglePepe, setTogglePepe] = useState(false)
const [toggleEth, setToggleEth] = useState(false)
const [toggle1, settoggle1] = useState(false)
const [toggle2, settoggle2] = useState(false)
const [toggle3, settoggle3] = useState(false)
const [toggle4, settoggle4] = useState(false)
const [toggleMAX, setToggleMAX] = useState(false)
const [round, setRound] = useState("0")
const [deadline, setDeadline] = useState(0)
const [showdown, setShowdown] = useState('')
const [score0, setScore0] = useState("0")
const [score1, setScore1] = useState("0")
const [totalAmountVince0, setTotalAmountVince0] = useState("0")
const [totalAmountVince1, setTotalAmountVince1] = useState("0")
const [minAmountVince0, setMinAmountVince0] = useState("0")
const [minAmountMaga0, setMinAmountMaga0] = useState("0")
const [minAmountPepe0, setMinAmountPepe0] = useState("0")
const [minAmountEth0, setMinAmountEth0] = useState("0")
const [minAmountVince1, setMinAmountVince1] = useState("0")
const [minAmountMaga1, setMinAmountMaga1] = useState("0")
const [minAmountPepe1, setMinAmountPepe1] = useState("0")
const [minAmountEth1, setMinAmountEth1] = useState("0")
const [roundToBeWithdrawn, setRoundToBeWithdrawn] = useState('')

const handleClickFighter0 = () => {
setToggleFighter0(true);
setToggleFighter1(false);
};

const handleClickFighter1 = () => {
setToggleFighter0(false);
setToggleFighter1(true);
};

const handleClickVince = () => {
setToggleMaga(false);
setTogglePepe(false);
setToggleEth(false);
setToggleVince(true);
};

const handleClickMaga = () => {
setToggleVince(false);
setTogglePepe(false);
setToggleEth(false);
setToggleMaga(true);
};

const handleClickPepe = () => {
setToggleVince(false);
setToggleMaga(false);
setToggleEth(false);
setTogglePepe(true);
};

const handleClickEth = () => {
setToggleVince(false);
setToggleMaga(false);
setToggleEth(true);
setTogglePepe(false);
};

const handleClick100 = () => {
settoggle1(true);
settoggle2(false);
settoggle3(false);
settoggle4(false);
setToggleMAX(false);
};

const handleClick250 = () => {
settoggle1(false);
settoggle2(true);
settoggle3(false);
settoggle4(false);
setToggleMAX(false);
};

const handleClick500 = () => {
settoggle1(false);
settoggle2(false);
settoggle3(true);
settoggle4(false);
setToggleMAX(false);
};

const handleClick1000 = () => {
settoggle1(false);
settoggle2(false);
settoggle3(false);
settoggle4(true);
setToggleMAX(false);
};

const handleClickMAX = () => {
settoggle1(false);
settoggle2(false);
settoggle3(false);
settoggle4(false);
setToggleMAX(true);
};

async function withdraw() {
var input = {roundToBeWithdrawn}
if (input.roundToBeWithdrawn == ""){
input.roundToBeWithdrawn = 0;
}
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"function withdraw(uint256 r) external"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
var nonce = await provider.getTransactionCount(signer.address);
await VinceFight.withdraw(input.roundToBeWithdrawn, {nonce:nonce})
console.log("Withdrew:",input.roundToBeWithdrawn)
}
catch (e) {
errorManager(e)}
}

async function initializeScores() {
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"function round() public view returns (uint256)",
"function score(uint256 round, bool fighter) public view returns (uint256)",
"function readTotalAmount(bool f) public view returns (uint256)",
"function readMinimumStandardQuotes(bool f) public view returns (uint256,uint256,uint256,uint256)",
"event ScoreUpdated(uint256 s, bool f)"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
var round = await VinceFight.round()

var score0OnChain = await VinceFight.score({round}.round,true)
score0OnChain = Number(String(score0OnChain).substr(0,String(score0OnChain).length-18))
setScore0(score0OnChain =='' ? '0' : score0OnChain)
var score1OnChain = await VinceFight.score({round}.round,false)
score1OnChain = Number(String(score1OnChain).substr(0,String(score1OnChain).length-18))
setScore1(score1OnChain =='' ? '0' : score1OnChain)

var totalAmount0OnChain = await VinceFight.readTotalAmount(true)
totalAmount0OnChain = Number(String(totalAmount0OnChain).substr(0,String(totalAmount0OnChain).length-18))
setTotalAmountVince0(totalAmount0OnChain =='' ? '0' : totalAmount0OnChain)
var totalAmount1OnChain = await VinceFight.readTotalAmount(false)
totalAmount1OnChain = Number(String(totalAmount1OnChain).substr(0,String(totalAmount1OnChain).length-18))
setTotalAmountVince1(totalAmount1OnChain =='' ? '0' : totalAmount1OnChain)

var minimumQuotes0 = await VinceFight.readMinimumStandardQuotes(true)
var minimumQuote0Vince = Number(String(minimumQuotes0[0]/BigInt(10**16)))/100
setMinAmountVince0(minimumQuote0Vince =='' ? '0' : minimumQuote0Vince)
var minimumQuote0Maga = Number(String(minimumQuotes0[1]/BigInt(10**7)))/100
setMinAmountMaga0(minimumQuote0Maga =='' ? '0' : minimumQuote0Maga)
var minimumQuote0Pepe = Number(String(minimumQuotes0[2]/BigInt(10**16)))/100
setMinAmountPepe0(minimumQuote0Pepe =='' ? '0' : minimumQuote0Pepe)
var minimumQuote0Eth = Number(String(minimumQuotes0[3]/BigInt(10**16)))/100
setMinAmountEth0(minimumQuote0Eth =='' ? '0' : minimumQuote0Eth)
var minimumQuotes1 = await VinceFight.readMinimumStandardQuotes(false)
var minimumQuote1Vince = Number(String(minimumQuotes1[0]/BigInt(10**16)))/100
setMinAmountVince1(minimumQuote1Vince =='' ? '0' : minimumQuote1Vince)
var minimumQuote1Maga = Number(String(minimumQuotes1[1]/BigInt(10**7)))/100
setMinAmountMaga1(minimumQuote1Maga =='' ? '0' : minimumQuote1Maga)
var minimumQuote1Pepe = Number(String(minimumQuotes1[2]/BigInt(10**16)))/100
setMinAmountPepe1(minimumQuote1Pepe =='' ? '0' : minimumQuote1Pepe)
var minimumQuote1Eth = Number(String(minimumQuotes1[3]/BigInt(10**16)))/100
setMinAmountEth1(minimumQuote1Eth =='' ? '0' : minimumQuote1Eth)
VinceFight.on("ScoreUpdated", (s,f) => {
if (f) {
score0OnChain = Number(String(s).substr(0,String(s).length-18))
setScore0(score0OnChain =='' ? '0' : score0OnChain)
} else {
score1OnChain = Number(String(s).substr(0,String(s).length-18))
setScore1(score1OnChain =='' ? '0' : score1OnChain)
}});
}
catch (e) {}}
  
async function support() {
var fighter={toggleFighter0}
if (!{toggleFighter0}.toggleFighter0 && !{toggleFighter1}.toggleFighter1){
fighter = "undefined";
}
var slippage = 420
if ({toggle1}.toggle1 && !{toggle2}.toggle2 && !{toggle3}.toggle3 && !{toggle4}.toggle4 && !{toggleMAX}.toggleMAX){
slippage = 100;
} else if (!{toggle1}.toggle1 && {toggle2}.toggle2 && !{toggle3}.toggle3 && !{toggle4}.toggle4 && !{toggleMAX}.toggleMAX){
slippage = 500;
} else if (!{toggle1}.toggle1 && !{toggle2}.toggle2 && {toggle3}.toggle3 && !{toggle4}.toggle4 && !{toggleMAX}.toggleMAX){
slippage = 1000;
} else if (!{toggle1}.toggle1 && !{toggle2}.toggle2 && !{toggle3}.toggle3 && {toggle4}.toggle4 && !{toggleMAX}.toggleMAX){
slippage = 2500;
} else if (!{toggle1}.toggle1 && !{toggle2}.toggle2 && !{toggle3}.toggle3 && !{toggle4}.toggle4 && {toggleMAX}.toggleMAX){
slippage = 9999;
}

try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const tokenAbi = [
"function approve(address _spender, uint256 _value) public returns (bool success)",
"function allowance(address _user, address _spender) public view returns (uint256)"]
const abi = [
"function supportVince(bool f, uint256 a, address t, uint256 s) external payable",
"event ScoreUpdated(uint256 scoreFighter, bool fighter)"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
const Vince = new Contract(vinceAddress, tokenAbi, signer)
const Maga = new Contract(magaAddress, tokenAbi, signer)
const Pepe = new Contract(pepeAddress, tokenAbi, signer)

if ({toggleVince}.toggleVince && !{toggleMaga}.toggleMaga && !{togglePepe}.togglePepe && !{toggleEth}.toggleEth){
var allowance = await Vince.allowance(signer.address, vinceFightAddress)
var inputWifDecimals = {amount}.amount == "" ? 0 : {amount}.amount % 1 !== 0 ? BigInt({amount}.amount * 10 ** 18) : BigInt({amount}.amount) * BigInt(10 ** 18)
if (allowance < inputWifDecimals) {
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await Vince.approve(vinceFightAddress, String(inputWifDecimals), {nonce: nonce});
var receipt = await transaction.wait();
}
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await VinceFight.supportVince(fighter.toggleFighter0, String(inputWifDecimals), vinceAddress, String(slippage), {nonce: nonce});
var receipt = await transaction.wait();

} else if (!{toggleVince}.toggleVince && {toggleMaga}.toggleMaga && !{togglePepe}.togglePepe && !{toggleEth}.toggleEth){
var allowance = await Maga.allowance(signer.address, vinceFightAddress)
var inputWifDecimals = {amount}.amount == "" ? 0 : {amount}.amount % 1 !== 0 ? BigInt({amount}.amount * 10 ** 9) : BigInt({amount}.amount) * BigInt(10 ** 9)
if (allowance < inputWifDecimals) {
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await Maga.approve(vinceFightAddress, String(inputWifDecimals), {nonce: nonce});
var receipt = await transaction.wait();
}
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await VinceFight.supportVince(fighter.toggleFighter0, String(inputWifDecimals), magaAddress, String(slippage), {nonce: nonce});
var receipt = await transaction.wait();

} else if (!{toggleVince}.toggleVince && !{toggleMaga}.toggleMaga && {togglePepe}.togglePepe && !{toggleEth}.toggleEth){
var allowance = await Pepe.allowance(signer.address, vinceFightAddress)
var inputWifDecimals = {amount}.amount == "" ? 0 : {amount}.amount % 1 !== 0 ? BigInt({amount}.amount * 10 ** 18) : BigInt({amount}.amount) * BigInt(10 ** 18)
if (allowance < inputWifDecimals) {
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await Pepe.approve(vinceFightAddress, String(inputWifDecimals), {nonce: nonce});
var receipt = await transaction.wait();
}
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await VinceFight.supportVince(fighter.toggleFighter0, String(inputWifDecimals), pepeAddress, String(slippage), {nonce: nonce});
var receipt = await transaction.wait();

} else if (!{toggleVince}.toggleVince && !{toggleMaga}.toggleMaga && !{togglePepe}.togglePepe && {toggleEth}.toggleEth){
var inputWifDecimals = {amount}.amount == "" ? 0 : {amount}.amount % 1 !== 0 ? BigInt({amount}.amount * 10 ** 18) : BigInt({amount}.amount) * BigInt(10 ** 18)
var nonce = await provider.getTransactionCount(signer.address);
var transaction = await VinceFight.supportVince(fighter.toggleFighter0, "0", wethAddress, String(slippage), {value: String(inputWifDecimals), nonce: nonce});
var receipt = await transaction.wait();
}
}
catch (e) {
errorManager(e)}
}

async function initializeRound() {
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"function round() public view returns (uint256)",
"event NewRound(uint256 round, uint256 deadline, bool winner)"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
var round = await VinceFight.round()
round = Number(String(round))
setRound(round);
//VinceFight.on("NewRound", (r, d, w) => {setRound(Number(String(r)));
//setCount(deadline-Date.now())});
} catch (e) {console.log(e)}}

async function initializeDeadline() {
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"function deadline() public view returns (uint256)",
"event NewRound(uint256 round, uint256 deadline, bool winner)",
"event DeadlineExtended(uint256 deadline)"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
var deadline = await VinceFight.deadline()
deadline = Number(String(deadline)) * 1000
setDeadline(deadline);
setShowdown(true);
//VinceFight.on("NewRound", (r, d, w) => {setDeadline(Number(String(d)) * 1000);});
//VinceFight.on("DeadlineExtended", (d) => {setDeadline(Number(String(d)) * 1000);});
}
catch (e) {
console.log(e)}
}

async function noChanceInHellYouGot() {
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"function noChanceInHellYouGot() public"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
const nonce = await provider.getTransactionCount(signer.address);
await VinceFight.noChanceInHellYouGot({nonce: nonce})
}
catch (e) {
errorManager(e)}
}

async function rerenderOnSolidityEvent() {
try{
const provider = new BrowserProvider(walletProvider)
const signer = await provider.getSigner()
const abi = [
"event NewRound(uint256 round, uint256 deadline, bool winner)",
"event DeadlineExtended(uint256 deadline)"]
const VinceFight = new Contract(vinceFightAddress, abi, signer)
VinceFight.on("NewRound", (r, d, w) => {setDeadline(Number(String(d)) * 1000);});
VinceFight.on("DeadlineExtended", (d) => {setDeadline(Number(String(d)) * 1000);});
}
catch (e) {console.log(e)}
}

initializeRound()
initializeScores()
initializeDeadline()
rerenderOnSolidityEvent()

//////////////////////////////////////////////////////////////////////////////////////////
//Algorithm to choose fighters

function splitmix32(a) {
 return function() {
   a |= 0;
   a = a + 0x9e3779b9 | 0;
   let t = a ^ a >>> 16;
   t = Math.imul(t, 0x21f0aaad);
   t = t ^ t >>> 15;
   t = Math.imul(t, 0x735a2d97);
   return ((t = t ^ t >>> 15) >>> 0) / 4294967296;
  }
}

var id0 = overwrite_rounds[1].id0
var id1 = overwrite_rounds[0].id1
var fighter0 = fighters[id0]
var fighter1 = fighters[id1]
var flag = false

// if round has to be overwritten, select overwritten fighters
for (let r = 0; r < overwrite_rounds.length; r++) {
  if (round == overwrite_rounds[r].round) {
  	var id0 = overwrite_rounds[r].id0
  	var id1 = overwrite_rounds[r].id1
  	var flag = true
  }}
// otherwise, generate randomly by selecting memes vs memes or memers vs memers
if (!flag) {
const prng = splitmix32(round);
var category = Math.floor( prng()*2 );
var category = 0; // Coming soon
if (category == 0) {
while (fighters[id0].category != "meme") {
var id0 = Math.floor( prng()*fighters.length );
}
var id1 = Math.floor( prng()*fighters.length );
while (id0 == id1 || fighters[id1].category != "meme") {
var id1 = Math.floor( prng()*fighters.length );}
} else if (category == 1) {
while (fighters[id0].category != "memer") {
var id0 = Math.floor( prng()*fighters.length );
}
var id1 = Math.floor( prng()*fighters.length );
while (id0 == id1 || fighters[id1].category != "memer") {
var id1 = Math.floor( prng()*fighters.length );}
}}
var fighter0 = fighters[id0]
var fighter1 = fighters[id1]

  return (
  <span>

	<Row className="align-items-center justify-content-center">
	<h3>Round: {round}</h3>
	</Row>
	<Row className="align-items-center mb-3">
<CountdownComponent deadline={{deadline}.deadline}/>
</Row>

	<Row className="align-items-center mb-3">
	<video autoPlay muted loop><source src="https://cdn.pixabay.com/video/2024/05/29/214404_large.mp4" type="video/mp4"></source></video>
	<Col xs={6} className="d-flex justify-content-center"><img src={fighter0.src} className="Wrestler1Image"/></Col>
	<Col xs={6} className="d-flex justify-content-center"><img src={fighter1.src} className="Wrestler2Image"/></Col>
	</Row>

	<Row className="align-items-center justify-content-center">
	<Col xs={6}><h2>{fighter0.name}</h2></Col>
	<Col xs={6}><h2>{fighter1.name}</h2></Col>
	</Row>
	
	<Container>
	
	
{(score0>=score1) &&
(<Row className="align-items-center justify-content-center mb-3">
<Col xs={6}><h3 className="ScoreTextH3">Score: </h3><h3 className="ScoreTextH3 green">{score0} <img className="TokenImage HideFromTablet" src={logo}/></h3></Col>
<Col xs={6}><h3 className="ScoreTextH3">Score: </h3><h3 className="ScoreTextH3 red">{score1} <img className="TokenImage HideFromTablet" src={logo}/></h3></Col>
<Col xs={6}><h5 className="ScoreTextH5">Score: </h5><h5 className="ScoreTextH5 green">{score0} <img className="TokenImage HideFromTablet" src={logo}/></h5></Col>
<Col xs={6}><h5 className="ScoreTextH5">Score: </h5><h5 className="ScoreTextH5 red">{score1} <img className="TokenImage HideFromTablet" src={logo}/></h5></Col>
</Row>)}
{(score0<score1) &&
(<Row className="align-items-center justify-content-center mb-3">
<Col xs={6}><h3 className="ScoreTextH3">Score: </h3><h3 className="ScoreTextH3 red">{score0} <img className="TokenImage HideFromTablet" src={logo}/></h3></Col>
<Col xs={6}><h3 className="ScoreTextH3">Score: </h3><h3 className="ScoreTextH3 green">{score1} <img className="TokenImage HideFromTablet" src={logo}/></h3></Col>
<Col xs={6}><h5 className="ScoreTextH5">Score: </h5><h5 className="ScoreTextH5 red">{score0} <img className="TokenImage HideFromTablet" src={logo}/></h5></Col>
<Col xs={6}><h5 className="ScoreTextH5">Score: </h5><h5 className="ScoreTextH5 green">{score1} <img className="TokenImage HideFromTablet" src={logo}/></h5></Col>
</Row>)}

  	<Row className="align-items-center justify-content-center mb-3">
	<div>
	<p className="inline">Support: </p>
	<ToggleButtonGroup type="radio" name="fighters" defaultValue={0}>
	<ToggleButton id="tbg-radio-1" value={1} variant="outline-danger" onClick={() => handleClickFighter0()}>
	<p className="inline">{fighter0.name}</p>
	</ToggleButton>
	<ToggleButton id="tbg-radio-2" value={2} variant="outline-danger" onClick={() => handleClickFighter1()}>
	<p className="inline">{fighter1.name}</p>
	</ToggleButton>
	</ToggleButtonGroup>
	</div>
	</Row>
    
	<Row className="align-items-center justify-content-center mb-3">
	<div>
	<p className="inline">Using: </p>
	<ToggleButtonGroup type="radio" name="token" defaultValue={0}>
	<ToggleButton id="tbg-radio-3" value={1}  variant="outline-danger" onClick={() => handleClickVince()} selected={toggleVince}>
	<p className="HideFromPhone">$VINCE </p><img className="TokenImage" src={logo}/>
	</ToggleButton>
	<ToggleButton id="tbg-radio-4" value={2}  variant="outline-danger" onClick={() => handleClickMaga()} selected={toggleMaga}>
	<p className="HideFromPhone">$MAGA </p><img className="TokenImage" src={logomaga}/>
	</ToggleButton>
	<ToggleButton id="tbg-radio-5" value={3} variant="outline-danger" onClick={() => handleClickPepe()} selected={togglePepe}>
	<p className="HideFromPhone">$PEPE </p><img className="TokenImage" src={logopepe}/>
	</ToggleButton>
	<ToggleButton id="tbg-radio-6" value={4} variant="outline-danger" onClick={() => handleClickEth()} selected={toggleEth}>
	<p className="HideFromPhone">$ETH </p><img className="TokenImage" src='https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'/>
	</ToggleButton>
	</ToggleButtonGroup>
	</div>
	</Row>
	
	{toggleVince && (
	<Row className="align-items-center justify-content-center">
	<h6 className="inline">Minimum input amount: {toggleFighter0 && minAmountVince0}{toggleFighter1 && minAmountVince1}{!toggleFighter1 && !toggleFighter0 && ("Select fighter")} $VINCE</h6>
	</Row>
	)}
	{toggleMaga && (
	<Row className="align-items-center justify-content-center">
	<h6 className="inline">Minimum input amount: {toggleFighter0 && minAmountMaga0}{toggleFighter1 && minAmountMaga1}{!toggleFighter1 && !toggleFighter0 && ("Select fighter")} $MAGA</h6>
	</Row>
	)}
	{togglePepe && (
	<Row className="align-items-center justify-content-center">
	<h6 className="inline">Minimum input amount: {toggleFighter0 && minAmountPepe0}{toggleFighter1 && minAmountPepe1}{!toggleFighter1 && !toggleFighter0 && ("Select fighter")} $PEPE</h6>
	</Row>
	)}
	{toggleEth && (
	<Row className="align-items-center justify-content-center">
	<h6 className="inline">Minimum input amount: {toggleFighter0 && minAmountEth0}{toggleFighter1 && minAmountEth1}{!toggleFighter1 && !toggleFighter0 && ("Select fighter")} $ETH</h6>
	</Row>
	)}
	{toggleFighter0 && (
	<Row className="align-items-center justify-content-center mb-2">
	<h6 className="inline">If {fighter0.name} wins, ROI: {
	100 * (totalAmountVince1/2) / totalAmountVince0 > 1
	? 100 * (totalAmountVince1/2) / totalAmountVince0 > 50
	? 50
	: String(100 * (totalAmountVince1/2) / totalAmountVince0).split('.')[0]
	: 0
	}%</h6>
	</Row>
	)}
	{toggleFighter1 && (
	<Row className="align-items-center justify-content-center mb-2">
	<h6 className="inline">If {fighter1.name} wins, ROI: {
	100 * (totalAmountVince0/2) / totalAmountVince1 > 1
	? 100 * (totalAmountVince0/2) / totalAmountVince1 > 50
	? 50
	: String(100 * (totalAmountVince0/2) / totalAmountVince1).split('.')[0]
	: 0
	}%</h6>
	</Row>
	)}
	
	{(toggleMaga || togglePepe || toggleEth) && (
	<Row className="align-items-center justify-content-center mb-3">
	<div>
	
	<p className="inline">Slippage: </p>
	<ToggleButtonGroup type="radio" name="slip" defaultValue={1}>
		<ToggleButton id="tbg-radio-7" value={5}  variant="outline-danger" onClick={() => handleClick100()}>
	<b>1%</b>
	</ToggleButton>	<ToggleButton id="tbg-radio-8" value={6}  variant="outline-danger" onClick={() => handleClick250()}>
	<b>5%</b>
	</ToggleButton>	<ToggleButton id="tbg-radio-9" value={7}  variant="outline-danger" onClick={() => handleClick500()}>
	<b>10%</b>
	</ToggleButton>	<ToggleButton id="tbg-radio-10" value={8}  variant="outline-danger" onClick={() => handleClick1000()}>
	<b>25%</b>
	</ToggleButton> <ToggleButton id="tbg-radio-11" value={9} variant="outline-danger" onClick={() => handleClickMAX()}>
	<b>MAX</b>
	</ToggleButton>
	</ToggleButtonGroup>
	</div>
	</Row>
	)}
  
  <Row className="align-items-center justify-content-center mb-3">
	<div>
	<InputGroup className="align-items-center justify-content-center">
	<Form.Control placeholder="Input amount" type="number" onChange={ e => setAmount(e.target.value)}/>
	<Button  variant="outline-danger" onClick={() => support()}>
	<b>Submit</b>
	</Button> 
	</InputGroup>
	</div>
	</Row>
	
	<Row className="align-items-center justify-content-center mb-3">
	<div>
	<InputGroup className="align-items-center justify-content-center">
	<Form.Control placeholder="Round to withdraw" type="number" onChange={ e => setRoundToBeWithdrawn(e.target.value)}/>
	<Button  variant="outline-danger" onClick={() => withdraw()}>
	<b>Withdraw</b>
	</Button> 
	</InputGroup>
	</div>
	</Row>
	
	<Button variant="outline-danger" onClick={() => noChanceInHellYouGot()}><b>Start New Match</b></Button>
	
	</Container>
	</span>)
}

//Render

function App() {
  
  return (
	<div className="App min-vh-100 d-flex justify-content-center">
	<Container>
	
        <Row className="align-items-center justify-content-center mb-3"></Row>
	<Row className="align-items-center justify-content-center mb-3">
	<Col><a className="red" href="https://vince.finance" target="_blank" rel="noopener noreferrer"><img desc="excited" src="https://media1.tenor.com/m/4VqLStYMgHAAAAAC/vince-mcmahon.gif" className="App-logo" /></a></Col>
	<Col className="HideFromPhone"><h2 className="red">Vince Fight</h2></Col>
	<Col className="d-flex justify-content-center"><w3m-button /></Col>
	</Row>

<MainApp/>
     
      	<img className="VinceGIF" desc="surprised" src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExcDR4cnVob3Bwb296Nms2Zm9ndGJ0ZjVpdW53M3B2cXBvN2t6c3ppdSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ohc1cssVhCXQTbmlW/giphy.webp"/>
	<img className="VinceGIF" desc="muscle" src="https://i.gifer.com/UJX9.gif"/>
	<img className="VinceGIF" desc="money" src="https://media1.tenor.com/m/-OATBuvUe60AAAAC/money-sniff.gif"/>
	<img className="VinceGIF" desc="entrance" src="https://media1.tenor.com/m/Ed8FuKVA_GgAAAAC/wwe-vince-mc-mahon.gif"/>
	
	<Row className="align-items-center justify-content-center mb-3">
	</Row>
	
        <Row className="align-items-center justify-content-center mb-3">
        <p><img className="TokenImage" src={logo}/> Earn $VINCE by supporting your favorite fighter <img className="TokenImage" src={logo}/> <br/> <br/>
        A match lasts 24 hours. <br/>
        Supporting 15 minutes before the end of the match, extends match duration by 15 minutes.<br/>
        You must support at least 4.2069% of current fighter score.<br/>
        $MAGA, $PEPE and $ETH get autoswapped for $VINCE and boost score by x1.25<br/>
        The fighter with higher score at the end of the match wins.<br/>
        Half of the loser $VINCE amount is burned and the other half gets allocated to winning players proportionally to how much they supported across all the winners.<br/>
        Losing players lose all the allocated amounts.<br/><br/>
        To withdraw the $VINCE you won, select the round you participated to and click Withdraw. You can always withdraw your awarded $VINCE of any completed match. This is the only way to withdraw funds from VINCE Fight contract.<br/>
        Once a match is over, a new match starts after a withdrawal or by clicking Start New Match.</p>
	</Row>

	
	<img className="VinceGIF" desc="car" src="https://media1.tenor.com/m/sHTvAIVer0gAAAAC/vince-mcmahon.gif"/>
	<img className="VinceGIF" desc="kiss_my" src="https://i.makeagif.com/media/9-15-2015/M2IdMz.gif"/>
	<img className="VinceGIF" desc="shaved" src="https://media1.tenor.com/m/ZTI5cYnH-8YAAAAd/wwe-vince-shave-cream-shaved-trump.gif"/>
	<img className="VinceGIF" desc="evil_laugh" src="https://media1.tenor.com/m/hoIur25LSEkAAAAd/laughing-hysterically-evil-laugh.gif"/>
	
	<Row className="align-items-center justify-content-center mb-3">
	
	<Col md={4} xs={0} className="d-flex justify-content-center"><img className="VinceGIF HideFromPhone" desc="okok" src="https://media1.tenor.com/m/u6tWQHXSqZEAAAAd/yeah-ok-sure-yeah.gif"/></Col>
	<Col md={4} xs={12} className="d-flex justify-content-center"><p>The most convenient way to swap <a className="red" href="https://www.pond0x.com/swap/eth/0xD29DA236dd4AAc627346e1bBa06A619E8c22d7C5" target="_blank" rel="noopener noreferrer">$MAGA</a> and <a className="red" href="https://www.pond0x.com/swap/eth/0x6982508145454Ce325dDbE47a25d4ec3d2311933" target="_blank" rel="noopener noreferrer">$PEPE</a> is <a className="red" href="https://www.pond0x.com" target="_blank" rel="noopener noreferrer">Pond0x</a>, you can use <a className="red" href="https://www.pond0x.com/bridge" target="_blank" rel="noopener noreferrer">Pond0x Bridge</a> to swap $VINCE.</p></Col>
	<Col md={4} xs={0} className="d-flex justify-content-center"><img className="VinceGIF" src={vince_water}/></Col>
	
	<p><br/>VINCE Fight contract address: <a className="red" href="https://etherscan.io/address/0xB8bB428f86e345f0652FB583FB23D8ECC4Abb85E" target="_blank" rel="noopener noreferrer">0xB8bB428f86e345f0652&shy;FB583FB23D8ECC4Abb85E</a> <br/>$VINCE Token contract address: <a className="red" href="https://etherscan.io/address/0xDccf3968b667e515C9FC952aA6Bf834eb9d8476c" target="_blank" rel="noopener noreferrer">0xDccf3968b667e515C9F&shy;C952aA6Bf834eb9d8476c</a><br/>$MAGA Token contract address: <a className="red" href="https://etherscan.io/address/0xD29DA236dd4AAc627346e1bBa06A619E8c22d7C5" target="_blank" rel="noopener noreferrer">0xD29DA236dd4AAc62734&shy;6e1bBa06A619E8c22d7C5</a><br/>$PEPE Token contract address: <a className="red" href="https://etherscan.io/address/0x6982508145454ce325ddbe47a25d4ec3d2311933" target="_blank" rel="noopener noreferrer">0x6982508145454ce325d&shy;dbe47a25d4ec3d2311933</a></p>
	</Row>
	
	<img className="VinceGIF" desc="trump" src="https://media1.tenor.com/m/3EZQtbJgcCQAAAAd/trump-donald-trump.gif"/>
	<img className="VinceGIF" desc="mog_ancestor" src="https://media1.tenor.com/m/p2vzFx_jIF0AAAAd/deal-with.gif"/>
	<img className="VinceGIF" desc="hulk" src="https://gifdb.com/images/high/vince-mcmahon-vs-hulk-hogan-re7c5lqp5b4le5mk.webp"/>
	<img className="VinceGIF" desc="sad" src="https://media1.tenor.com/m/lu5kvvLkkyoAAAAd/vince-mcmahon-it-was-special.gif"/>
	
	<br/>
	<Row className="align-items-center justify-content-center">
	<b>Do not connect your wallet to this website if you don't think it's safe. Any player takes full responsibility for any action performed on this website or contract, which may or may not function as desired. Vince Fight has no association with Vince, WWE or any meme used here. This website is simply paying homage to a celebrity we all love and recognize, as well as Internet culture and memes, and it is intended for entertainment purposes only.</b>
	</Row>
	
	<img className="VinceGIF" desc="kiss1" src="https://media1.tenor.com/m/X-v62Uss3dsAAAAd/vinceandsable-sableandvince-sablewwe-vincemcmahon-sablevincekiss.gif"/>
	<img className="VinceGIF" desc="kiss2" src="https://i.makeagif.com/media/9-18-2023/c81DiO.gif"/>
	<img className="VinceGIF" desc="kiss3" src="https://i.makeagif.com/media/5-21-2015/qO2063.gif"/>
	<img className="VinceGIF" desc="youb" src="https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExMmxleTRzbGQ1cGg3OTVwZzF0dm9tYjcwZjYxZGp5ZDVoMndyYngwMyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/26gN1oSVP7uDClFLy/giphy.webp"/>
	
	<br/><br/>
	
    </Container>
    </div>
  );
}

export default App;
